.icon-search {
	display: inline-block;
	width: 24px;
	height: 24px;
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTE1Ny42NjY2NywxNDMuMzMzMzNsLTE0LjMzMzMzLDE0LjMzMzMzbC00MywtNDN2LTE0LjMzMzMzaDE0LjMzMzMzeiI+PC9wYXRoPjxwYXRoIGQ9Ik02NC41LDExNC42NjY2N2MtMjcuOTUsMCAtNTAuMTY2NjcsLTIyLjIxNjY3IC01MC4xNjY2NywtNTAuMTY2NjdjMCwtMjcuOTUgMjIuMjE2NjcsLTUwLjE2NjY3IDUwLjE2NjY3LC01MC4xNjY2N2MyNy45NSwwIDUwLjE2NjY3LDIyLjIxNjY3IDUwLjE2NjY3LDUwLjE2NjY3YzAsMjcuOTUgLTIyLjIxNjY3LDUwLjE2NjY3IC01MC4xNjY2Nyw1MC4xNjY2N3pNNjQuNSwyOC42NjY2N2MtMjAuMDY2NjcsMCAtMzUuODMzMzMsMTUuNzY2NjcgLTM1LjgzMzMzLDM1LjgzMzMzYzAsMjAuMDY2NjcgMTUuNzY2NjcsMzUuODMzMzMgMzUuODMzMzMsMzUuODMzMzNjMjAuMDY2NjcsMCAzNS44MzMzMywtMTUuNzY2NjcgMzUuODMzMzMsLTM1LjgzMzMzYzAsLTIwLjA2NjY3IC0xNS43NjY2NywtMzUuODMzMzMgLTM1LjgzMzMzLC0zNS44MzMzM3oiPjwvcGF0aD48cGF0aCBkPSJNOTAuNDcwMzcsOTYuMDQyMjVsNS4wNjgzMSwtNS4wNjY4OWwxNy43MzQxMiwxNy43MzkwN2wtNS4wNjgzMSw1LjA2Njg5eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+")
		50% 50% no-repeat;
	background-size: 100%;
}
.icon-calendar {
	display: inline-block;
	width: 24px;
	height: 24px;
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAACZUlEQVRYhe1WQYvTUBD+5iUtLStFhILgUj2I7EWEkEJJLyu7Clu8uV78AetV90/s1at/QC+yJ8Xdg1ihhpY8AnXpYfHgYauXQi+FkrYx42GTEmLNpm2KCPud8jIzb76ZN2/mAZe4RAJIKVlKyavQp79tkNTZPNB1/Q9/YhWO5oEaJwwYBxmZFcEsRPXjMhpLIEokKebRjyWwqloI4/+ogWURl8l/noGFCEQbzbyNKoxEt2BZMPOGEEJPbLBMRPNipTWQJJBEBJKcebvdXmu322sX7WXbdtG27WKwTqUGOp3OleFw+B4AGo3Go3w+X2Pm3RDhT0T0hojeeZ535BPZ0jStt1Arjq4Hg8FVVVWvA7iTy+W6zFyIbLHJzJvM/AuAAuAkEKRSA5VKpet53jYAF0ABQJeZnziOU3Acp0BEjwF88527ruvWNE3rAQmPIMk0VBRlh5lVAN3xeHzPMIx+SHxommY9m81+BXAjk8nsAngJpHsLngIAM78wDKNvWVZNSnkmpTyzLGvHMIw+Ee37OtP6WHocB9lhPr8Uo9HoGACI6BWA9dB3iZmPfbNqYJd6H1BVda4BtvB7gJk3yuXyaejV0wBQzWQy2wAOmXnPjxxEtOfbPCAiAKjrun7/QgJx8Hv7abAmorfMXGXmg1ar9blcLn8AUArkpmleI6IDX/f11G5RAlE0m811VVW/4zyoH0S0P5lMjgBAUZSHvvPbAFwhxE1N036mRsC27aLneR8B3AUQNJtZmDYiIcSWpmm9tIvwRAhRIqJnAOqh/18APHdd9xZCXTBVRIcMMHtozdJbGZKM498lmTZnAiY3IwAAAABJRU5ErkJggg==")
		50% 50% no-repeat;
	background-size: 100%;
}
