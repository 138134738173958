.searchbox__checkin:focus + .calendar-checkin {
	display: block;
}
.calendar-checkin:hover {
	display: block;
}

.searchbox__checkout:focus + .calendar-checkout {
	display: block;
}
.calendar-checkout:hover {
	display: block;
}

/*mobile version*/
@media screen and (max-width: 1024px) {
	#calendar-checkin,
	#calendar-checkout {
		position: fixed;
		width: 100vw !important;
		left: 0 !important;
		bottom: 0 !important;
	}
	.calendar__content {
		display: block !important;
		height: 350px;
		overflow-y: scroll;
	}
}

.xp-calendar .calendar {
	display: none;
	width: 540px;
	background: #fff;
	position: absolute;
	padding: 16px;
	z-index: 999999;
	left: 0;
	top: 12px;
	border: 0;
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 16px rgb(0 0 0 / 15%);
	box-shadow: 0 2px 16px rgb(0 0 0 / 15%);
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.calendar__display {
	margin-top: 50px;
	text-align: center;
}

.calendar__content {
	display: flex;
}

.calendar__wrapper {
	width: 250px;
	height: 260px;
	margin-right: auto;
	margin-left: auto;
}

.calendar__month {
	text-align: center;
	margin-top: 8px;
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
}

.calendar__day-name {
	height: -webkit-calc(4px * 9);
	height: calc(4px * 9);
	line-height: -webkit-calc(4px * 9);
	line-height: calc(4px * 9);
	text-align: center;
	padding: 0;
	border-spacing: 0;
}
.calendar__day-name {
	color: #6b6b6b;
}

.calendar__date--selected,
.calendar__date--selected:hover {
	color: #fff !important;
	background: #0071c2;
}
.calendar__date--today {
	color: #0071c2;
}
.calendar__date--in-range {
	background: #e9e9e9;
}
.calendar__date--disabled {
	color: #949494;
	background: 0;
	cursor: default;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.calendar__date {
	height: -webkit-calc(4px * 9);
	height: calc(4px * 9);
	line-height: -webkit-calc(4px * 9);
	line-height: calc(4px * 9);
	text-align: center;
	padding: 0;
	border-spacing: 0;
}

.calendar__date {
	cursor: pointer;
	position: relative;
}

.calendar__date--empty,
.calendar__date--empty:hover {
	background: 0;
	cursor: default;
}

td,
th {
	padding: 0;
	border: 0;
	text-align: left;
	vertical-align: top;
	font-weight: normal;
}

td {
	display: table-cell;
	vertical-align: inherit;
}

.calendar__dates {
	width: 100%;
	table-layout: fixed;
	border-spacing: 0;
}

table {
	border-spacing: 0;
	border: 0;
}

table {
	border-collapse: separate;
	text-indent: initial;
	border-spacing: 2px;
}
